<template>
  <Page class="balance-page" :hasBg="false" :loading="!loading">
    <!-- 导航栏 -->
    <NavBar title="账户余额">
      <!-- <template #right>
        <p @click="handleJump(PageEnum.BALANCE_RECORDS)" class="text_primary">提现记录</p>
      </template> -->
    </NavBar>
    <div class="balance-content-card pt-24">
      <div class="balance-top-card border-box d-flex flex-column mx-24 relative">
        <div>
          <div class="flex justify-between items-center mt-12">
            <span class="ml-22 text-16">
              当前余额(元)
            </span>
            <div
              class="withdraw-record-btn flex justify-center items-center mr-14"
              @click="handleJump(PageEnum.BALANCE_RECORDS)"
            >
              提现记录
            </div>
          </div>
          <div class="text-32 mt-16 text-center fw-500">{{ useFormatMoney(balanceRef / 100) }}</div>
          <Button
            @click="handleWithdraw"
            class="flex justify-center items-center text-16 mt-16 w-112 h-36 withdraw-btn m-auto text-white"
          >
            提现
          </Button>
          <!-- <div class="mt-24">
          <Button size="large" @click="handleWithdraw" :disabled="balanceRef === 0" type="primary">提现</Button>
        </div> -->
        </div>
        <div class="bg-circle"></div>
      </div>

      <div class="mt-50 mx-24">
        <div class="flex justify-between w-full">
          <span class="text-18 text-main font-500">资金明细</span>
          <span class="text-primary text-16" @click="handleJump(PageEnum.BALANCE_DETAIL)">查看全部</span>
        </div>

        <div>
          <div v-if="flowListRef.length > 0">
            <FlowCardItem v-for="item in flowListRef" :key="item.accountFundFlowId" :itemData="item"></FlowCardItem>
          </div>
          <div v-else>
            <div class="flex flex-col items-center p-32">
              <img
                class="w-134 h-134"
                src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_wuzijinmingxi%403x.png"
              />
              <span class="text-muted text-16">无资金明细</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, computed, onBeforeMount, ref } from '@vue/composition-api';
import NavBar from '@/components/NavBar/index';
import { Button, Dialog, Toast } from 'vant';
import useFormatMoney from '@/hook/useFormatMoney';
import { PageEnum } from '@/enums/pageEnum';
import { useBalanceStore } from '@/store/balance';
import FlowCardItem from './components/FlowCardItem';

export default defineComponent({
  components: {
    FlowCardItem,
    NavBar,
    Dialog,
    Button,
    Toast
  },
  setup(_, ctx) {
    const balanceStore = useBalanceStore();
    const balanceRef = computed(() => balanceStore.balance);
    const allowWithdrawRef = computed(() => balanceStore.bankList.length > 0);
    const flowListRef = computed(() => balanceStore.flowList.slice(0, 6));
    const loading = ref(false);

    onBeforeMount(async () => {
      Toast.loading('加载中...');
      await balanceStore.queryFundList();
      balanceStore.queryBalance();
      balanceStore.queryBanklist();
      loading.value = true;
    });

    const handleJump = item => {
      ctx.root.$router.push(item);
    };

    const handleWithdraw = () => {
      if (allowWithdrawRef.value) {
        handleJump(PageEnum.BALANCE_WITHDRAW);
      } else {
        Dialog.confirm({
          message: '暂未银行卡，请前往添加!'
        })
          .then(() => {
            handleJump(PageEnum.BANK);
          })
          .catch(() => {});
      }
    };

    return {
      loading,
      PageEnum,
      balanceRef,
      flowListRef,
      allowWithdrawRef,
      handleWithdraw,
      useFormatMoney,
      handleJump
    };
  }
});
</script>
<style lang="less">
.balance-page .page__content {
  background: #fff;
}
.balance-content-card {
  .bg-circle {
    position: absolute;
    top: 0;
    left: 0;
  }
  .withdraw-record-btn {
    width: 0.88rem;
    height: 0.32rem;
    border: 1px solid #ffffff;
    border-radius: 0.5rem;
    background: transparent;
    color: #fff;
    font-size: 0.14rem;
  }
  .withdraw-btn {
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.2) !important;
    border: none;
  }
  .balance-top-card {
    border-radius: 0.08rem;
    min-height: 1.8rem;
    background: linear-gradient(134deg, #0076ff 0%, #62abff 100%);
    color: #fff;
  }
  .icon-circle {
    background: rgba(64, 152, 255, 0.1);
    border-radius: 50%;
    width: 0.32rem;
    height: 0.32rem;
  }
  .withdrawal-btn {
    background: #ffffff;
    border: 1px solid #0076ff;
    height: 0.46rem;
    border-radius: 0px 4px 4px 0px;
  }
  .balance-title {
    border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  }
}
</style>
