<template>
  <div class="flow-item py-12 box-border" v-if="itemData">
    <div class="d-flex" @click="handleJump">
      <!-- <div class="lh-24">
        <Icon :name="InComeEnum[itemData.incomeType].icon" />
      </div> -->

      <div class="d-flex justify-space-between flex-column flow-item-right">
        <div class="d-flex justify-space-between">
          <div class="text-14">
            <span class="mr-8" v-if="itemData.incomeType === 1">收入</span>
            <span class="mr-8" v-if="itemData.incomeType === 2">
              <span v-if="itemData.type === 8">收入退款</span>
              <span v-else>提现</span>
            </span>

            <span class="text_muted" v-if="itemData.incomeType === 1">
              {{ `${itemData.confirmShareDriverName || ''} ${itemData.confirmSharePhone || ''}` }}
            </span>
            <span v-if="itemData.incomeType === 2">
              <span v-if="itemData.type === 8"></span>
              <span v-else>
                {{ itemData.bankName }}
                {{ itemData.bankCardNumber ? `(${itemData.bankCardNumber.slice(-4)})` : '' }}
              </span>
            </span>
          </div>
          <div class="text-14">
            {{ itemData.incomeType === 1 ? '+' : '-' }} {{ useFormatMoney(itemData.tradeAmount / 100) }}
          </div>
        </div>
        <div class="d-flex mt-8 text_muted">
          {{ formatToDateTime(itemData.tradeTime, 'MM月dd日 hh:mm') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { PageEnum } from '@/enums/pageEnum';
import { InComeEnum } from '../constants';
import useFormatMoney from '@/hook/useFormatMoney';
import { formatToDateTime } from '@wlhy-web-lib/shared';

export default defineComponent({
  props: {
    itemData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const handleJump = () => {
      ctx.root.$router.push({
        path: PageEnum.BALANCE_DETAILINFO,
        query: {
          id: props.itemData.accountFundFlowId
        }
      });
    };
    return {
      PageEnum,
      InComeEnum,
      handleJump,
      useFormatMoney,
      formatToDateTime
    };
  }
});
</script>
<style lang="less">
.flow-item {
  height: 0.72rem;
  border-bottom: 0.5px solid #f5f5f5;
  .flow-item-right {
    flex: 1;
  }
}
</style>
