export const InComeEnum = [
  {},
  {
    name: '收入',
    icon: 'in',
    symbol: '+'
  },
  {
    name: '提现',
    icon: 'out',
    symbol: '-'
  },
  {},
  {},
  {},
  {},
  {},
  {
    name: '收入退款',
    icon: 'out',
    symbol: '-'
  }
];
