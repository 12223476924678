import { defineStore } from 'pinia';
import { apiSearchAccountFundFlowList, apiSearchBalance } from '@/api/balance';
import { getBankList } from '@/api/mine';
import { useUserStore } from '@/store/user';

const userInfo = useUserStore();

export const useBalanceStore = defineStore({
  id: 'balance',
  state: () => ({
    balance: 0,
    flowList: [],
    bankList: []
  }),
  getters: {},
  actions: {
    async queryFundList(params) {
      try {
        const res = await apiSearchAccountFundFlowList({
          driverIdCard: userInfo.idCard,
          ...params
        });
        const { data } = res;
        data && (this.flowList = data);
        // console.log(this.flowList);
      } catch (error) {
        console.error(error);
      }
    },
    async queryBalance(params) {
      try {
        const res = await apiSearchBalance({
          driverIdCard: userInfo.idCard,
          ...params
        });
        const { data } = res;
        data && (this.balance = data);
      } catch (error) {
        console.error(error);
      }
    },
    async queryBanklist() {
      try {
        const res = await getBankList();
        const { data } = res;
        data && (this.bankList = data);
      } catch (error) {
        console.error(error);
      }
    }
  }
});
